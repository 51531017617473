import React, { useState, useRef } from "react";
import { motion } from "framer-motion";
import Slider from "react-slick";

import DjangoLogoPositiveImg from "../../assets/images/kindlebit-tab-logos/back-end-development-tools/django-logo-positive.png";
import LaravelImg from "../../assets/images/kindlebit-tab-logos/back-end-development-tools/laravel.png";
import MongoDBLogoSvg from "../../assets/images/kindlebit-tab-logos/back-end-development-tools/MongoDB_Logo.svg";
import MySQLLogoImg from "../../assets/images/kindlebit-tab-logos/back-end-development-tools/MySQL-Logo.png";
import NginxLogoSvg from "../../assets/images/kindlebit-tab-logos/back-end-development-tools/Nginx_logo.svg";
import NodeJsLogoSvg from "../../assets/images/kindlebit-tab-logos/back-end-development-tools/Node-js_logo.svg";
import PhpLogoImg from "../../assets/images/kindlebit-tab-logos/back-end-development-tools/php-logo.png";

function Techwork() {
  const sliderRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const sliderData = [
    { id: 1, title: "Back-end Dev Tools" },
    { id: 2, title: "Cloud Computing" },
    { id: 3, title: "Database" },
  ];

  const mainSettings = {
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 499,
        settings: {
          slidesToShow: 5,
        },
      },
    ],
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    vertical: true,
    loop: true,
    verticalSwiping: true,
    centerMode: true,
    centerPadding: "0px",
    afterChange: (currentSlide) => setActiveSlide(currentSlide),
  };

  const goToSlide = (index) => {
    sliderRef.current.slickGoTo(index);
  };

  return (
    <section className="techwork">
      <div
        className="back-orange-art"
        data-aos="fade-right"
        data-aos-duration="800"
        data-aos-delay="300"
        data-aos-offset="0"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="252.883"
          height="123.877"
          viewBox="0 0 252.883 123.877"
        >
          <g
            id="Group_68"
            data-name="Group 68"
            transform="translate(-4187.117 -707.061)"
          >
            <rect
              id="Rectangle_99"
              data-name="Rectangle 99"
              width="123.877"
              height="252.883"
              rx="61.939"
              transform="translate(4440 707.061) rotate(90)"
              fill="#f38020"
              opacity="0.05"
            />
            <rect
              id="Rectangle_100"
              data-name="Rectangle 100"
              width="74.596"
              height="202.142"
              rx="37.298"
              transform="translate(4414.629 731.702) rotate(90)"
              fill="#f38020"
              opacity="0.05"
            />
            <rect
              id="Rectangle_101"
              data-name="Rectangle 101"
              width="35.472"
              height="150.653"
              rx="17.736"
              transform="translate(4388.885 751.264) rotate(90)"
              fill="#f38020"
              opacity="0.05"
            />
            <rect
              id="Rectangle_102"
              data-name="Rectangle 102"
              width="10.297"
              height="111.304"
              rx="5.148"
              transform="translate(4369.21 763.851) rotate(90)"
              fill="#f38020"
              opacity="0.05"
            />
          </g>
        </svg>
      </div>

      <div className="techwork-inner container">
        <div className="sec-dual-heading">
          <div className="sec-heading">
            <div
              className="sap-caption-head"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="800"
              data-aos-offset="0"
            >
              <div className="sap-caption">OUR PROFICIENCY</div>
            </div>
            <div
              className="sap-lg-heading"
              data-aos="fade-down"
              data-aos-duration="800"
              data-aos-delay="300"
              data-aos-offset="0"
            >
              Technologies
            </div>
          </div>
          <div
            className="text-para"
            data-aos="fade-left"
            data-aos-duration="800"
            data-aos-delay="800"
            data-aos-offset="0"
          >
            Supercharge Your Business: Dive into the Future with Our Expertise!
            Access the latest tech stacks, managed by our Solution Architects
            and Tech Leads. From web to mobile, and DevOps mastery, we're your
            partners in pioneering success. Embrace innovation with us – Your
            Future Starts Now!
          </div>
        </div>
        <div className="tools-n-tech-tab">
          <div className="tools-n-tech-slider-head">
            <div className="left-sec">
              <Slider
                ref={sliderRef}
                {...mainSettings}
                className="tools-n-tech-slider owl-carousel owl-theme main-slider"
              >
                {sliderData.map((ele, index) => {
                  return (
                    <div
                      key={ele.id}
                      id={ele.id}
                      onClick={() => goToSlide(index)}
                    >
                      <div className={`sap-sm-heading`}>{ele.title}</div>
                    </div>
                  );
                })}
              </Slider>
            </div>
            <div className="right-sec">
              {activeSlide === 0 ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  <div className="home-tech-thumb-head">
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src={DjangoLogoPositiveImg}
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Django</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src={LaravelImg}
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Laravel</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src={MongoDBLogoSvg}
                          alt=""
                        />
                      </span>
                      <span className="htti-title">MongoDB</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src={MySQLLogoImg}
                          alt=""
                        />
                      </span>
                      <span className="htti-title">MySQL</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src={NginxLogoSvg}
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Nginx</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src={NodeJsLogoSvg}
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Node.js</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src={PhpLogoImg}
                          alt=""
                        />
                      </span>
                      <span className="htti-title">PHP</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/cloud-computing/aws-logo.png"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Amazon Web Services</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/cloud-computing/Bitcoin.svg"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Bitcoin</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/cloud-computing/Microsoft_Azure_Logo.svg"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Microsoft Azure</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/cloud-computing/private-cloud.png"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Private Cloud</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/databases-data-storages/MongoDB_Logo.svg"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">MongoDB</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/databases-data-storages/MySQL-Logo.png"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">MySQL</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/databases-data-storages/Postgresql_elephant.svg"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">PostgreSQL Elephant</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/design/Adobe_XD_CC_icon.png"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Adobe XD</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/design/ai.png"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Adobe Illustrator</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/design/figma.png"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Figma</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/design/ps.png"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Adobe Photoshop</span>
                    </div>
                  </div>
                </motion.div>
              ) : (
                ""
              )}
              {activeSlide === 1 ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  <div className="home-tech-thumb-head">
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/java/JPA.jpg"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Java Persistence API</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/java/spring-boot.png"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Spring Boot</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/java/spring-security.png"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Spring Security</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/java/Spring_Framework_Logo_2018.svg"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Spring Framework</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/php/codeigniter.png"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">CodeIgniter</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/php/drupal-wordmark.png"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Drupal Wordmark</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/php/Joomla!-Logo.svg"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Joomla</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/php/laravel.png"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Laravel</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/php/magento.png"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Magento</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/php/Prestashop.svg"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">PrestaShop</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/php/symphony.png"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Symphony</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/php/wp.png"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">WordPress</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/marketing-platforms/campaign-monitor-vector-logo.png"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Campaign Monitor</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/marketing-platforms/Constant-contact-logo.svg"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Constant Contact</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/marketing-platforms/Google_Ads_logo.png"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Google Ads</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/marketing-platforms/Mailchimp-logo.png"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Mailchimp</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/marketing-platforms/optimizely-vector-logo-small-2021.png"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Optimizely</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/marketing-platforms/unbounce-vector-logo.png"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Unbounce</span>
                    </div>
                  </div>
                </motion.div>
              ) : (
                ""
              )}
              {activeSlide === 2 ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  <div className="home-tech-thumb-head">
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/design/sketch.png"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Sketch</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/front-end-development-tools/angular-logo.png"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Angular</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/front-end-development-tools/Bootstrap_logo.svg"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Bootstrap</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/front-end-development-tools/css3-logo.png"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Cascading Style Sheet</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/front-end-development-tools/Ionic_Logo.svg"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Ionic</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/front-end-development-tools/JQuery_logo.svg"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">jQuery</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/front-end-development-tools/react-logo.png"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">React</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/front-end-development-tools/Typescript_logo_2020.svg"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">TypeScript</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/front-end-development-tools/vue-logo.png"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Vue.js</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/java/Hibernate_logo.svg"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Hibernate</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/java/jawa-swing.png"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Swing</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/java/jee.svg"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Jakarta EE</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/marketing-tools/1_A6kkoOVJVpXPWewg8axc5w.png"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Canva</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/marketing-tools/58b87d711b65fe46c38abc1f.png"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Ahrefs</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/marketing-tools/2560px-HubSpot_Logo.png"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">HubSpot</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/marketing-tools/Buffer-Logo.png"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Buffer</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/marketing-tools/Hootsuite-Logo.png"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Hootsuite</span>
                    </div>
                    <div className="home-tech-thumb-inner">
                      <span className="htti-img">
                        <img
                          src="/assets/images/kindlebit-tab-logos/marketing-tools/hotjar-logo-55CF8EB2D9-seeklogo.png"
                          alt=""
                        />
                      </span>
                      <span className="htti-title">Hotjar</span>
                    </div>
                  </div>
                </motion.div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Techwork;
