import { Fragment } from "react";
import Typewriter from "typewriter-effect";
import CountUp from "react-countup";

import KbsFirstFoldVideoUpdatedVid from "../../assets/videos/kbs_first_fold_video_updated.mp4";

function Hero() {
  const countUpEndValues = {
    clients: 4568,
    projects: 9759,
  };

  return (
    <Fragment>
      <section className="hero dark hero-white-nav">
        <div className="hero-inner container">
          <div className="hero-left">
            <div
              className="hero-heading sap-lg-heading"
              data-aos="fade-down"
              data-aos-duration="800"
              data-aos-delay="300"
              data-aos-offset="0"
            >
              <main>
                We are in the <span className="orange-text">Business </span>
                of
                <span className="typed-text">
                  <Typewriter
                    options={{
                      strings: ["understanding", "optimizing", "growing"],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </span>
                your Business.
              </main>
            </div>
            <div className="prg-counter">
              <div
                className="prg-container row"
                data-aos="fade-in"
                data-aos-duration="800"
                data-aos-delay="800"
                data-aos-offset="0"
              >
                <CountUpItem title="experience" end={15} />
                <CountUpItem
                  title="happy clients"
                  end={countUpEndValues.clients}
                />
                <CountUpItem
                  title="delivered projects"
                  end={countUpEndValues.projects}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="hero-backdrop-vid">
          <video
            width="320"
            height="240"
            autoPlay
            preload="auto"
            loop
            muted
            playsInline={true}
          >
            <source src={KbsFirstFoldVideoUpdatedVid} type="video/mp4" />
          </video>
        </div>
      </section>
    </Fragment>
  );
}

const CountUpItem = ({ title, end }) => {
  return (
    <div
      className="col-md-2"
      data-aos="fade-right"
      data-aos-duration="800"
      data-aos-delay="800"
      data-aos-offset="0"
    >
      <h3 className="prg-count">
        <CountUp start={0} end={end} duration={3} />
      </h3>
      <h4 className="prg-count-title">{title}</h4>
    </div>
  );
};

export default Hero;
